import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';

import { tablet, useQuery } from 'styles/breakpoints';

import theme from 'utils/theme';

interface OurPromiseProps {
  title?: string;
  text?: string;
  bottomText?: string;
  bgImageDes?: string;
  bgImageMob?: string;
  signatureImg?: string;
  bgColor?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 2rem;
  gap: 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Card = styled.div<{ $bgColor?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 16px;
  background: ${({ $bgColor }) => $bgColor || theme.colors.cultured};
  padding: 3rem 6rem;
  max-width: 960px;
  object-fit: cover;
  @media ${tablet} {
    padding: 2.25rem 1rem;
    gap: 1.5rem;
  }
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  @media ${tablet} {
    text-align: center;
  }
`;
const StyledText = styled(Text)`
  line-height: 140%;
  letter-spacing: -0.432px;
  @media ${tablet} {
    letter-spacing: -0.336px;
  }
`;
const StyledBottomText = styled(Text)`
  line-height: 140%;
  strong {
    font-size: 138%;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-width: 100%;
  z-index: -1;
  border-radius: 1rem;
  @media ${tablet} {
    height: 100%;
  }
`;

const StyledSignatureImg = styled(DynamicImage)`
  position: absolute;
  bottom: 3rem;
  right: 6rem;
  width: 100px;
  height: 70px;
  @media ${tablet} {
    position: relative;
    bottom: auto;
    right: auto;
  }
`;

const OurPromise: FC<OurPromiseProps> = ({
  title,
  text,
  bgImageDes,
  bgImageMob,
  signatureImg,
  bottomText,
  bgColor,
}) => {
  const { isTablet, isMobile } = useQuery();

  return (
    <Container>
      <Card $bgColor={bgColor}>
        {bgImageDes && bgImageMob && (
          <StyledDynamicImage
            src={isMobile ? bgImageMob : bgImageDes}
            height="100%"
            width="100%"
            objectFit="fill"
            alt={'background'}
          />
        )}

        <Title type={isTablet ? 'h21625Rem' : 'h12375Rem700'}>{title}</Title>

        {text && (
          <StyledText
            type={isMobile ? 'bodyM' : 'h4400'}
            color="dark100"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}

        {bottomText && (
          <StyledBottomText
            type={isMobile ? 'bodyM' : 'body'}
            color="dark100"
            dangerouslySetInnerHTML={{
              __html: bottomText,
            }}
          />
        )}

        {signatureImg && (
          <StyledSignatureImg src={signatureImg} alt={'signature copy'} />
        )}
      </Card>
    </Container>
  );
};

export default OurPromise;
