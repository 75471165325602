import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';

import { tablet, useQuery } from 'styles/breakpoints';

import theme from 'utils/theme';

interface OurPromiseProps {
  title?: string;
  text?: string;
  bottomText?: string;
  bgImageDes?: string;
  bgImageMob?: string;
  signatureImg?: string;
  bgColor?: string;
}

const Container = styled.div<{ $bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 1rem;
  width: 100%;
  gap: 3rem;
  margin: auto;
  background: ${({ $bgColor }) => $bgColor || 'transparent'};
  @media ${tablet} {
    gap: 1.5rem;
    padding: 2.25rem 0;
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4rem;
  // max-width: 960px;
  object-fit: cover;
  width: 100%;
  max-width: 1280px;

  @media ${tablet} {
    flex-direction: column-reverse;
    padding: 0 1rem;
    gap: 1.5rem;
  }
`;
const TextContainer = styled.div<{ $bgColor?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 522px;
  object-fit: cover;
  // width: 50%;
  @media ${tablet} {
    width: 100%;
    gap: 1.5rem;
  }
`;

const StyledText = styled(Text)`
  line-height: 150%;
  letter-spacing: -0.432px;
  @media ${tablet} {
    letter-spacing: -0.336px;
  }
`;
const StyledBottomText = styled(Text)`
  line-height: 140%;
  strong {
    font-size: 138%;
  }
`;

// const ImageContainer = styled.div`
//   min-width: 50%;
//   max-width: 635px;
//   height: 100%;
//   min-height: 100%;
// 	flex-grow: 1;
//   // height: 650px;
// 	background:red;
//   @media ${tablet} {
//     width: 100%;
//     height: 250px;
//   }
// `;
const StyledDynamicImage = styled(DynamicImage)`
  width: 40%;
  min-width: 40%;
  max-width: 40%;
  min-height: 100%;
  max-height: 720px;
  object-fit: cover;
  object-position: center top;
  border-radius: 1rem;
  img {
    object-position: center top;
    object-fit: cover;
  }
  @media ${tablet} {
    border-radius: 0;
    max-height: 300px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const StyledSignatureImg = styled(DynamicImage)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 70px;
  margin-left: auto;
  @media ${tablet} {
    position: relative;
    bottom: auto;
    right: auto;
    margin-left: 0;
  }
`;
const BottomTextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  widht: 100%;
  @media ${tablet} {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;

const StyledTitle = styled(Text)`
  text-align: center;
`;

const OurPromiseHorizontal: FC<OurPromiseProps> = ({
  title,
  text,
  image,
  imageMobile,
  signatureImg,
  bottomText,
  bgColor,
}) => {
  const { isTablet } = useQuery();

  return (
    <Container $bgColor={bgColor}>
      <Text type={isTablet ? 'h2' : 'h1700'} color="dark100">
        {title}
      </Text>
      <Card>
        <TextContainer>
          {text && (
            <StyledText
              type={isTablet ? 'bodyM' : 'h4400'}
              color="dark100"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}

          {bottomText && (
            <BottomTextWrapper>
              <StyledBottomText
                type={isTablet ? 'bodyM' : 'body'}
                color="dark100"
                dangerouslySetInnerHTML={{
                  __html: bottomText,
                }}
              />
              {signatureImg && (
                <StyledSignatureImg src={signatureImg} alt={'signature copy'} />
              )}
            </BottomTextWrapper>
          )}
        </TextContainer>
        {image && imageMobile && (
          <StyledDynamicImage
            src={isTablet ? imageMobile : image}
            height="100%"
            width="100%"
            objectFit="cover"
            alt={'background'}
          />
        )}
      </Card>
    </Container>
  );
};

export default OurPromiseHorizontal;
