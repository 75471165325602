import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import ContentContainer from './ContentContainer';
import StartNowButton from './StartNowButton';
import { mobile, useQuery } from 'styles/breakpoints';

interface DescriptionProps {
  title: string;
  subtitle?: string;
  onClick?: () => void;
  children?: ReactNode;
  mobileSmTitle?: boolean;
  mobileCenterTitle?: boolean;
  startBtnTitle?: string;
}

const Container = styled(ContentContainer)`
  margin-bottom: 6rem;
  @media ${mobile} {
    margin-bottom: 3rem;
  }
`;

const TitleContainer = styled.div<{ mobileCenter?: boolean }>`
  margin-bottom: 2.5rem;

  @media ${mobile} {
    text-align: ${({ mobileCenter }) => (mobileCenter ? 'center' : 'left')};
  }
`;

const Subtitle = styled(Text).attrs({
  color: 'dark100',
})`
  margin-top: 1rem;
  text-align: center;
`;

const Button = styled(StartNowButton)`
  margin: 3rem auto 0 auto;
  @media ${mobile} {
    margin-top: 2rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  @media ${mobile} {
    font-size: 1.625rem;
  }
`;

const Description: FC<DescriptionProps> = ({
  title,
  subtitle,
  onClick,
  children,
  mobileSmTitle,
  mobileCenterTitle,
  startBtnTitle,
}) => {
  const { isMobile } = useQuery();
  return (
    <Container>
      <TitleContainer mobileCenter={mobileCenterTitle}>
        <Title
          type={isMobile ? (mobileSmTitle ? 'h2600' : 'countS') : 'countS'}
          color="dark100"
        >
          {title}
        </Title>
        {subtitle && (
          <Subtitle type={isMobile ? 'body' : 'h4400'}>{subtitle}</Subtitle>
        )}
      </TitleContainer>
      {children}
      {onClick && startBtnTitle ? (
        <Button onClick={onClick}>{startBtnTitle}</Button>
      ) : null}
    </Container>
  );
};

export default React.memo(Description);
